/* eslint-disable no-undef */
import React from "react";

interface MarkerOptions extends google.maps.MarkerOptions {
  optimized?: boolean;
  importcode: string;
  description: string;
  infowindow: google.maps.InfoWindow;
  icon: any;
  position: any;
  closeAllInfoWindows: () => void;
  map?: google.maps.Map;
}

const Marker: React.FC<MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
      marker.addListener("click", () => {
        options?.map?.setCenter(options.position);
        options?.closeAllInfoWindows();
        options?.infowindow?.open(options.map, marker);
      });
    }
  }, [marker, options]);

  return null;
};

export default Marker;
