import { Wrapper } from "@googlemaps/react-wrapper";
import PriceComparison from "./price-comparison/price-comparison";

export default function Root(props) {
  return (
    <Wrapper apiKey={"AIzaSyD1TE3AO2uxwIkTvfvvXezofBNFZ98zau4"}>
      <PriceComparison parentDataRef={props.compRef} />
    </Wrapper>
  );
}
