import React, { useEffect } from "react";
import { cloneDeep, isEqual } from "lodash";
import images from "../images";

import "rc-pagination/assets/index.css";
import "./price-table.css";

const PriceTable = (props) => {
  const { storesData, isPagination } = props;
  const countPerPage = 4;
  const [collection, setCollection] = React.useState(
    cloneDeep(storesData.slice(0, countPerPage))
  );

  const [page, setPage] = React.useState(4);

  const getPrice = (data, product, isCompare = false) => {
    const list = data.listOfProduct || data.prices || [];
    let price = "N/A";
    let found = [];
    // currentPrice
    found = list.filter(
      (x) => x.product === product || x.productName === product
    );
    if (found.length === 0 && data.listOfProductOpis && isPagination) {
      found = data.listOfProductOpis.filter(
        (x) => x.product === product || x.productName === product
      );
    } else if (found.length === 0 && data.opisPrices) {
      found = data.opisPrices.filter(
        (x) => x.product === product || x.productName === product
      );
    }

    if (found && found.length) {
      if (!isPagination || !isCompare) {
        price = found[0].retail;
      } else {
        price = found[0].currentPrice;
      }
    }
    return price;
  };

  const comparePrices = (data, product) => {
    if (props.storeData) {
      const price = props.storeData ? getPrice(props.storeData, product) : [];
      const priceForCompare = getPrice(data, product, true);
      if (priceForCompare !== "N/A" && price !== "N/A") {
        const finalprice =
          Math.round((Number(priceForCompare) - Number(price)) * 100) / 100;
        return Number(priceForCompare) > Number(price) ? (
          <span style={{ color: "green", fontSize: "7px" }}>
            {" "}
            (+{finalprice})
          </span>
        ) : (
          <span style={{ color: "red", fontSize: "7px" }}> ({finalprice})</span>
        );
      }
    }
    return null;
  };

  const showMore = () => {
    if (storesData.length != page) {
      setCollection(cloneDeep(storesData.slice(0, page + 4)));
      if (page < storesData.length) {
        const diff = storesData.length - page;
        if (diff > 4) {
          setPage(page + 4);
        } else {
          setPage(page + diff);
        }
      } else setPage(storesData.length);
    }
  };

  const showLess = () => {
    setCollection(cloneDeep(storesData.slice(0, 4)));
    setPage(4);
  };

  const selectForComparison = (storeData) => {
    if (!props.selectForComparison) {
      return;
    }
    props.selectForComparison(storeData);
  };

  useEffect(() => {
    setCollection(cloneDeep(storesData.slice(0, countPerPage)));
  }, [props.storesData]);

  return (
    <>
      <div
        className={`price-table-container ${isPagination ? "pagination" : ""}`}
      >
        <table>
          {!props.isPagination && (
            <thead>
              <tr>
                <th>Icon</th>
                <th>Store ID</th>
                <th>Regular</th>
                <th>Plus</th>
                <th>Supreme</th>
                <th>Diesel</th>
              </tr>
            </thead>
          )}
          <tbody className="trhover">
            {collection.map((data, idx) => {
              return (
                <tr
                  key={`${data.importcode}${idx}`}
                  onClick={() => selectForComparison(data)}
                  className={
                    isEqual(data, props.selectedStore)
                      ? "selected"
                      : !isPagination
                      ? "price-table-bg"
                      : ""
                  }
                >
                  <td>
                    <img
                      className="comparison-icon-style"
                      src={
                        !isPagination
                          ? images[data.icon]
                          : images[data.brandname.toLowerCase() + ".png"]
                      }
                      alt=" "
                    />
                  </td>
                  <td
                    className={!isPagination ? "own_store" : "comparison_store"}
                    style={{
                      color: data.isCompetitor ? "purple" : "orange",
                    }}
                  >
                    {data.importcode}
                    {isPagination && (
                      <div className="distance-miles">
                        ({Math.round(data[0] * 100) / 100}mi)
                      </div>
                    )}
                  </td>
                  <td className={!isPagination ? "own_store_scn" : ""}>
                    {getPrice(data, "Regular", props.isPagination)}
                    {comparePrices(data, "Regular")}
                  </td>
                  <td className={!isPagination ? "own_store_scn" : ""}>
                    {getPrice(data, "Plus", props.isPagination)}
                    {comparePrices(data, "Plus")}
                  </td>
                  <td className={!isPagination ? "own_store_scn" : ""}>
                    {getPrice(data, "Supreme", props.isPagination)}
                    {comparePrices(data, "Supreme")}
                  </td>
                  <td className={!isPagination ? "own_store_scn" : ""}>
                    {getPrice(data, "Diesel", props.isPagination)}
                    {comparePrices(data, "Diesel")}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination-style">
        {page != countPerPage && isPagination && storesData.length > 4 && (
          <span className="show-more" onClick={() => showLess()}>
            Show less
          </span>
        )}
        {storesData.length != page && isPagination && storesData.length > 4 && (
          <span className="show-more" onClick={() => showMore()}>
            Show More
          </span>
        )}
      </div>
    </>
  );
};

export default PriceTable;
