import img711 from "./resources/logos/711.png"
import img76 from "./resources/logos/76.png"
import allsups from "./resources/logos/allsups.png"
import alon from "./resources/logos/alon.png"
import Arco from "./resources/logos/Arco.png"
import bolton from "./resources/logos/bolton.png"
import bp from "./resources/logos/bp.png"
import bucees from "./resources/logos/bucees.png"
import Bullochlogo from "./resources/logos/Bulloch-logo.png"
import chevron from "./resources/logos/chevron.png"
import circlek from "./resources/logos/circlek.png"
import Circle_K from "./resources/logos/Circle_K.png"
import citgo from "./resources/logos/citgo.gif"
import conoco from "./resources/logos/conoco.png"
import conocophillips from "./resources/logos/conocophillips.png"
import Email_Onlylogo from "./resources/logos/Email_Only-logo.png"
import exxon from "./resources/logos/exxon.png"
import flyingJ from "./resources/logos/flyingJ.png"
import Gilbarcologo from "./resources/logos/Gilbarco-logo.png"
import Gulf from "./resources/logos/Gulf.png"
import heb from "./resources/logos/heb.png"
import kroger from "./resources/logos/kroger.png"
import loves from "./resources/logos/loves.png"
import lowes from "./resources/logos/lowes.png"
import marathon from "./resources/logos/marathon.png"
import mobil from "./resources/logos/mobil.png"
import murphy from "./resources/logos/murphy.png"
import phillips66 from "./resources/logos/phillips66.png"
import quiktrip from "./resources/logos/quiktrip.png"
import racetrac from "./resources/logos/racetrac.png"
import raceway from "./resources/logos/raceway.png"
import sacNpac from "./resources/logos/sacNpac.png"
import sams_club from "./resources/logos/sams_club.png"
import shell from "./resources/logos/shell.png"
import skinnys from "./resources/logos/skinnys.png"
import snappy from "./resources/logos/snappy.png"
import speedway from "./resources/logos/speedway.png"
import speedystop from "./resources/logos/speedystop.png"
import stripes from "./resources/logos/stripes.png"
import stripes_sunoco from "./resources/logos/stripes_sunoco.png"
import stripesTruckstop from "./resources/logos/stripesTruckstop.png"
import sunoco from "./resources/logos/sunoco.png"
import texaco from "./resources/logos/texaco.png"
import united from "./resources/logos/united.png"
import unknown from "./resources/logos/unknown.png"
import valero from "./resources/logos/valero.png"
import Verifonelogo from "./resources/logos/Verifone-logo.png"
import walmart from "./resources/logos/walmart.png"
import wawa from "./resources/logos/wawa.png"
import petrocanada from "./resources/logos/petrocanada.png"


const images = {
"711.png" : img711, 
"76.png" : img76, 
"allsups.png" : allsups, 
"alon.png" : alon, 
"Arco.png" : Arco, 
"bolton.png" : bolton, 
"bp.png" : bp, 
"bucees.png" : bucees, 
"Bulloch-logo.png" : Bullochlogo, 
"chevron.png" : chevron, 
"circlek.png" : circlek, 
"Circle_K.png" : Circle_K, 
"citgo.gif" : citgo, 
"conoco.png" : conoco, 
"conocophillips.png" : conocophillips, 
"Email_Only-logo.png" : Email_Onlylogo, 
"exxon.png" : exxon, 
"flyingJ.png" : flyingJ, 
"Gilbarco-logo.png" : Gilbarcologo, 
"Gulf.png" : Gulf, 
"heb.png" : heb, 
"kroger.png" : kroger, 
"loves.png" : loves, 
"lowes.png" : lowes, 
"marathon.png" : marathon, 
"mobil.png" : mobil, 
"murphy.png" : murphy, 
"phillips66.png" : phillips66, 
"quiktrip.png" : quiktrip, 
"racetrac.png" : racetrac, 
"raceway.png" : raceway, 
"sacNpac.png" : sacNpac, 
"sams_club.png" : sams_club, 
"shell.png" : shell, 
"skinnys.png" : skinnys, 
"snappy.png" : snappy, 
"speedway.png" : speedway, 
"speedystop.png" : speedystop, 
"stripes.png" : stripes, 
"stripes_sunoco.png" : stripes_sunoco, 
"stripesTruckstop.PNG" : stripesTruckstop,
"sunoco.png" : sunoco, 
"texaco.png" : texaco, 
"united.png" : united, 
"unknown.png" : unknown, 
"valero.png" : valero, 
"Verifone-logo.png" : Verifonelogo, 
"walmart.png" : walmart, 
"wawa.png" : wawa,
"petrocanada.png" : petrocanada

}

export default images;
