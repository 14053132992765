import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import CalculateIcon from "@mui/icons-material/Calculate";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import images from "../images";
import RangeSelector from "../range-selector/range-selector";
import PriceTable from "../price-table/price-table";
import "./price-comparison.css";
import Map from "../map/MapComponent";
import Marker from "../map/Marker";

const getSVGMarker = (color: string, scale: number, strokeWeight = 0) => {
  const svgMarker = {
    path: "M384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192H384z",
    fillColor: color,
    fillOpacity: 1,
    strokeWeight,
    strokeColor: "#004225",
    rotation: 0,
    scale,
    labelOrigin: new google.maps.Point(200, 200),
  };
  return svgMarker;
};

const getInfowindow = (importcode: string, description: string) => {
  let contentString = `<p>Site ID: ${importcode}</p>`;
  contentString += `<p>Description: ${description}</p>`;
  const infowindow = new google.maps.InfoWindow({
    content: contentString,
  });
  return infowindow;
};

const PriceComparison = ({ parentDataRef }) => {
  const [storeData, setStoreData] = useState(null);
  const [storesData, setStoresData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [radius, setRadius] = useState(1);
  const [calculateDisabled, setCalculateDisabled] = useState(true);
  const [priceData, setPriceData] = useState({});
  const [mapBounds, setMapBounds] = useState<any>();
  const [showLoader, setShowLoader] = useState(false);
  const otherLocationsMarkers = useMemo(() => {
    const otherLocations = storesData?.map((store) => {
      return {
        latLng: {
          lat: store?.location?.lat,
          lng: store?.location?.lon,
        },
        icon: getSVGMarker(
          store?.isCompetitor ? "#5956bc" : "#f67810",
          selected?.importcode == store?.importcode ? 0.1 : 0.06
        ),
        importcode: store?.importcode,
        description: store?.description ?? store?.importcode,
        infowindow: getInfowindow(
          store?.importcode,
          store?.description ?? store?.importcode
        ),
      };
    });
    return otherLocations || [];
  }, [storesData, selected]);

  const primaryLocationMarker = useMemo(() => {
    const primaryLocation = {
      latLng: storeData?.location,
      icon: getSVGMarker("#4CBB17", 0.1, 2),
      importcode: storeData?.importcode,
      description: storeData?.description ?? storeData?.importcode,
      infowindow: getInfowindow(
        storeData?.importcode,
        storeData?.description ?? storeData?.importcode
      ),
    };
    return primaryLocation;
  }, [storeData]);

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();
    primaryLocationMarker?.latLng &&
      bounds.extend(primaryLocationMarker.latLng);
    otherLocationsMarkers?.forEach(
      (location) => location.latLng && bounds.extend(location.latLng)
    );
    (primaryLocationMarker?.latLng || otherLocationsMarkers?.length) &&
      setMapBounds(bounds);
  }, [otherLocationsMarkers, primaryLocationMarker]);

  const selectForComparison = (data) => {
    setSelected(data);
  };

  useEffect(() => {
    if (parentDataRef && parentDataRef.storeData) {
      parentDataRef.mapLayerService
        .fetchLocationPrices(parentDataRef.storeData.importcode)
        .subscribe((data: any) => {
          setPriceData(data);
          const x = {
            ...parentDataRef.storeData,
            ...data,
            location: parentDataRef.storeData.position.toJSON(),
          };
          setStoreData(x);
        });
      calculateButton(radius);
    }
  }, []);

  const calculateButton = (radiusVal?: number) => {
    setShowLoader(true);
    setCalculateDisabled(true);
    parentDataRef.mapLayerService
      .getPriceCompareData(
        parentDataRef.storeData.position.toJSON(),
        (radiusVal || radius) + "mi"
      )
      .subscribe((data: any) => {
        const list = data.hits.hits.map((x) => {
          return { ...x.sort, ...x["_source"] };
        });

        const importCodes = list.map((x) => x.importcode);
        if (importCodes.length) {
          parentDataRef.mapLayerService
            .getPriceComparePrices(importCodes)
            .subscribe((data: any) => {
              setShowLoader(false);
              const mergedList = list.map((x) => {
                const y = data.find((obj) => obj.importcode === x.importcode);
                return {
                  ...x,
                  ...y,
                };
              });
              setStoresData(
                mergedList.filter(
                  (x) => x.importcode !== parentDataRef.storeData.importcode
                )
              );
            });
        }
      });
  };

  const resetButton = () => {
    setRadius(1);
    setSelected(null);
    calculateButton(1);
  };

  const onCloseIcon = () => {
    parentDataRef.closeDialog();
  };

  const closeAllInfoWindows = () => {
    primaryLocationMarker?.infowindow?.close();
    otherLocationsMarkers.forEach((marker) => {
      marker?.infowindow?.close();
    });
  };

  return (
    <div
      className="price-comparison-wrapper"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="price-comparison-header-container">
        <div className="price-comparison-header">Store Proximity Analysis</div>
        <span style={{ paddingLeft: "100px" }}>
          <RangeSelector
            setRadius={setRadius}
            radius={radius}
            setCalculateDisabled={setCalculateDisabled}
          />
        </span>
        <Stack direction="row" spacing={2} style={{ paddingTop: "15px" }}>
          <Button
            variant="outlined"
            color="success"
            startIcon={<RestartAltIcon />}
            onClick={resetButton}
            className="button_style"
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="success"
            disabled={calculateDisabled}
            onClick={() => calculateButton(radius)}
            startIcon={<CalculateIcon />}
            className="button_style"
          >
            Calculate
          </Button>
        </Stack>
        <div
          className="close-button fas fa-lg fa-times-circle"
          title="Close"
          onClick={onCloseIcon}
        />
      </div>
      <div style={{ paddingLeft: "6px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            border: (theme) => `2px solid ${theme.palette.divider}`,
            borderRadius: 1,
            bgcolor: "background.paper",
            color: "text.secondary",
            padding: 1,
          }}
        >
          <img
            className="icon-style"
            src={priceData ? images[priceData["icon"]] : ""}
            alt={"logo"}
          />
          <Divider orientation="vertical" flexItem />
          <span className="store-details">StoreID: </span>
          <span className="store-details-data">{priceData["importcode"]} </span>
          <Divider orientation="vertical" flexItem />
          <span className="store-details">Brand: </span>
          <span className="store-details-data">
            {priceData["brand"] ? priceData["brand"] : "N/A"}
          </span>
          <Divider orientation="vertical" flexItem />
          <span className="store-details">OpisID: </span>
          <span className="store-details-data">
            {priceData["opisId"] ? priceData["opisId"] : "N/A"}
          </span>
        </Box>
      </div>
      <div className="price-comparison-container">
        <div className="price-comparison-data-container">
          {storeData && (
            <PriceTable
              storesData={[storeData]}
              isPagination={false}
              selectedStore={selected}
            />
          )}
          {((storeData === null && storesData.length === 0) || showLoader) && (
            <Box sx={{ display: "flex" }} className="loader_position">
              <CircularProgress />
            </Box>
          )}
          {storesData && storesData.length > 0 && !showLoader && (
            <PriceTable
              selectForComparison={selectForComparison}
              storesData={storesData}
              storeData={storeData}
              selectedStore={selected}
              isPagination={true}
            />
          )}
        </div>
        {storeData && (
          <div className="price-comparison-map-container">
            <Map
              zoom={4}
              center={{ lat: 41.124656, lng: -100.770216 }}
              mapBounds={mapBounds}
              style={{
                height: "400px",
                width: "550px",
                overflow: "hidden",
                borderRadius: "5px",
              }}
            >
              {primaryLocationMarker && (
                <Marker
                  optimized={true}
                  importcode={primaryLocationMarker?.importcode}
                  description={primaryLocationMarker?.description}
                  infowindow={primaryLocationMarker?.infowindow}
                  closeAllInfoWindows={closeAllInfoWindows}
                  icon={primaryLocationMarker?.icon}
                  key="primary-location"
                  position={primaryLocationMarker?.latLng}
                />
              )}
              {otherLocationsMarkers?.map((marker, i) => (
                <Marker
                  optimized={true}
                  importcode={marker?.importcode}
                  description={marker?.description}
                  infowindow={marker?.infowindow}
                  closeAllInfoWindows={closeAllInfoWindows}
                  icon={marker?.icon}
                  key={i}
                  position={marker?.latLng}
                />
              ))}
            </Map>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceComparison;
